// extracted by mini-css-extract-plugin
export var content = "post-details-module--content--Y0UCZ";
export var projectContent = "post-details-module--project-content--oqkAt";
export var imageContainer = "post-details-module--image-container--Y-+Ln";
export var image = "post-details-module--image--JWTgO";
export var textContainer = "post-details-module--text-container--APX+C";
export var typeDate = "post-details-module--type-date--GjU83";
export var type = "post-details-module--type--T6Vbu";
export var date = "post-details-module--date--6N5EZ";
export var author = "post-details-module--author--4+DX+";
export var embeddedImg = "post-details-module--embedded-img--B6SNQ";
export var button = "post-details-module--button--fQhm7";
export var buttonText = "post-details-module--button-text--fFqyC";
export var arrow = "post-details-module--arrow--7k0AZ";
export var relArticles = "post-details-module--rel-articles--55EI2";
export var titleContainer = "post-details-module--title-container--rqCvP";
export var sectionTitle = "post-details-module--sectionTitle--B+c06";
export var videoContainer = "post-details-module--video-container--4ujnv";
export var topLinks = "post-details-module--top-links--GEcJP";
export var bottomLinks = "post-details-module--bottom-links--+NxMw";